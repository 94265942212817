<template>
  <div class="card-body pb-0">
    <div v-if="item.publications_regtopics_removed">
      <b-alert show>
        <strong>{{item.publications_regtopics_removed[0].reason}}</strong> - {{item.publications_regtopics_removed[0].username}}
      </b-alert>
    </div>
    <div v-if="item.langCode !== 'en'">
      <small>auto-translated</small>
    </div>
    <h4 class="card-title">
      <strong v-if="item.refreshed">
        {{ item.name }} <small v-if="showLink">[<router-link :to="{ name: 'NewsItem', params: { id: item.id}}" >{{ item.id }}</router-link>]</small><small v-else>[{{ item.id }}]</small>
      </strong>
      <span v-else>
        {{ item.name }} <small v-if="showLink">[<router-link :to="{ name: 'NewsItem', params: { id: item.id}}" >{{ item.id }}</router-link>]</small><small v-else>[{{ item.id }}]</small>
      </span>
      <span v-if="$config.BUILD === 'full'">
        <span v-if="permissionDeletePublication && item.type == 'manual' || username == 'lucas.ocelewicz@kpmg.co.uk' || username == 'kate.dawson@kpmg.co.uk'">
          <b-button class="mx-2" variant="outline-primary" v-on:click="deletePublication">delete</b-button>
        </span>
        <span v-if="permissionDeletePublication && item.type == 'manual' || username == 'lucas.ocelewicz@kpmg.co.uk'">
          <b-button class="mx-2" variant="outline-primary" :to="{ name: 'FormEdit', params: { id: item.id, model: 'publication' }}">edit</b-button>
        </span>
      </span>
    </h4>
    <div class="mb-2"><b-link :to="{ name: 'Regulator', params: { code: item.publisher }}">{{ item.source }}</b-link> - {{ moment(item.dateTime).format('YYYY-MM-DD HH:mm') }} - {{ item.countryName }}</div>
    <div class="mb-2">{{ body }}</div>
    <div class="mb-2">
      external source: <a :href="item.link" target="_blank">{{ item.link }} <i class="fa fa-external-link" aria-hidden="true"></i></a>
    </div>
    <div v-if="permissionInsights">
      <div class="py-2 mb-2" v-if="item.articlesPublished.length > 0">
        <b-alert show>
          <strong>KPMG Insights: </strong>
          <span v-for="article in item.articlesPublished" :key="'article' + article.id">
            <router-link :to="{ name: 'Article', params: { id: article.id}}" >{{article.title}} | </router-link>
          </span>
        </b-alert>
      </div>
    </div>
    <div v-if="$config.BUILD === 'full' && permissionArticlesWip">
      <div class="mb-2" v-if="item.articlesWip.length > 0">
        <b-alert show>
          <strong>KPMG Insights (WIP): </strong>
          <span v-for="article in item.articlesWip" :key="'article' + article.id">
            <router-link :to="{ name: 'Article', params: { id: article.id}}" >{{article.title}} | </router-link>
          </span>
        </b-alert>
      </div>
    </div>
    <div class="mb-2" v-if="item.events.length > 0">
      <strong>Events: </strong>
      <span v-for="(event, index) in item.events" :key="'event' + event.id">
        <b-link :to="{ name: 'Event', params: { id: event.id } }">{{ moment(event.date).format('YYYY-MM-DD') }}: {{event.name}}</b-link> <a v-if="index + 1 < item.events.length"> | </a>
      </span>
    </div>
    <div class="mb-2" v-if="item.regtopics.length > 0">
      <strong>Topics: </strong>
      <span v-for="(topic, index) in item.regtopics" :key="'topic' + topic.id">
        <router-link :to="{ name: 'Topic', params: { id: topic.id}}" >{{topic.name}}</router-link>
        <span v-if="permissionReadTagsPanel && isTopicAutoTagged(topic.id) && showAutoTagsBadge"><b-badge class="ml-2">auto tagged</b-badge></span>
        <a v-if="index + 1 < item.regtopics.length"> | </a>
      </span>
    </div>
    <div class="mb-2" v-if="item.risks.length > 0">
      <strong>Risks: </strong>
      <span v-for="(risk, index) in item.risks" :key="'risk' + risk.id">
        <router-link :to="{ name: 'Risk', params: { id: risk.id}}" >{{risk.name}}</router-link><a v-if="index + 1 < item.risks.length"> | </a>
      </span>
    </div>
    <div class="mb-2" v-if="item.tags.length > 0">
      <strong>Types: </strong>
      <span v-for="(tag, index) in item.tags" :key="'tag' + tag.id">
        <b-link :to="{name: 'NewsTag', params: { id: tag.id }}">{{ tag.name }}</b-link>
        <span v-if="permissionReadTagsPanel && isTypeAutoTagged(tag.id) && showAutoTagsBadge"><b-badge class="ml-2">auto tagged</b-badge></span>
        <a v-if="index + 1 < item.tags.length"> | </a>
      </span>
    </div>
    <div class="my-4 p-2" v-if="item.summary" style="border-color: lightgray; border-style: solid; border-width: 1px;">
      <div class="mb-2"><small>Content generated by artifical intelligence - may contain mistakes</small></div>
      {{  item.summary }}
      <div v-if="item.keywords">
        <div v-if="item.keywords.length > 0" class="my-2">
          <strong>Keywords: </strong>
          <span v-for="(keyword, index) in item.keywords" :key="item.id + 'keyword' + keyword.id">
            <b-link :to="{ name: 'Keyword', params: { keyword: encodeURIComponent(keyword.name) }}">{{ keyword.name }}</b-link><a v-if="index + 1 < item.keywords.length"> | </a>
          </span>
        </div>
     </div>
     <div v-if="permissionDeletePublication">
        <b-button class="mt-2" variant="outline-primary" v-on:click="deleteAi">delete summary and keywords</b-button>
     </div>
    </div>
    <span v-if="item.riclists && permissionAddArticle">
      <div class="mb-2" v-if="item.riclists.length > 0">
        <strong>RIC Lists: </strong>
        <span v-for="(riclist, index) in item.riclists" :key="'riclist' + riclist.id">
          <router-link :to="{ name: 'Riclist', params: { id: riclist.id}}" >{{riclist.name}}</router-link><a v-if="index + 1 < item.riclists.length"> | </a>
        </span>
      </div>
    </span>
    <span v-if="permissionReadTagsPanel">
      <div class="mb-3 text-center" v-if="item.name != 'DELETED'">
        <b-form inline>
          <b-button class="mr-2" variant="outline-primary" v-on:click="showEvents">events</b-button>
          <b-button class="mr-2" variant="outline-primary" v-on:click="regTopics">topics</b-button>
          <span v-if="permissionAddRisk"><b-button class="mr-2" variant="outline-primary" v-on:click="risks">risks</b-button></span>
          <span v-if="permissionAddTag"><b-button class="mr-2" variant="outline-primary" v-on:click="tagsEdit">types</b-button></span>
          <span v-if="permissionAddArticle"><b-button class="mr-2" variant="outline-primary" v-on:click="addArticle">new article</b-button></span>
          <span v-if="permissionAddArticle"><b-button class="mr-2" variant="outline-primary" v-on:click="riclists">RIC lists</b-button></span>
        </b-form>
      </div>
    </span>
    <div v-if="showEventsFlag" class="mb-4">
      <div class="mb-4">
        <small>::RELATED EVENTS::</small> <span v-if="loadingEvents"><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></span>
      </div>
      <div class="mb-2" v-if="!loadingEvents && this.events == 0">There are no linked events.</div>
      <div v-for="event in events" :key="'event2' + event.id">
        <event :event="event" :permissions="eventPermissions" :username="username" :showRelatedNews="false" :publicationId="item.id" @eventDeleted="updateEventCount"></event>
      </div>
      <b-button variant="outline-primary" v-on:click="createEvent" v-if="eventStep==0">add event</b-button>
    </div>

    <!-- adding events -->
    <!-- STEP 1: selecting date -->
    <div v-if="eventStep!=0" class="mb-3">
      <h4>Adding a new event</h4>
    </div>
    <span v-if="eventStep==1">
      <div>
        <div class="mb-4">
          <label for="dateInput">Select date of the event:</label>
          <b-form-datepicker id="dateInput" v-model="form.date" ></b-form-datepicker>
        </div>
        <b-button class="mr-2" variant="danger" v-on:click="eventStepCancel">Cancel</b-button>
        <span v-if="validStep1"><b-button v-on:click="eventStepNext">Next >></b-button></span>
      </div>
    </span>

    <!-- STEP 2: linking existing events -->
    <span v-if="eventStep==2">
      <div>
        <div class="mb-2"><strong>Event date:</strong> {{ this.form.date }}</div>
        <div class="mb-4" v-if="eventsDay.length>0">
          <b-alert show>Do you want to link any of the existing events below?</b-alert>
          <div v-for="event in eventsDay" :key="'event3' + event.id">
            <div>
              <strong>{{ event.name }}</strong>
            </div>
            <div>{{ event.comments }}</div>
            <div class="mb-3">
              <b-button variant="outline-primary" v-on:click="linkEvent($event)" :uid="event.id">link this event</b-button>
            </div>
          </div>
        </div>
        <b-button class="mr-2" variant="danger" v-on:click="eventStepCancel">Cancel</b-button>
        <b-button class="mr-2" v-on:click="eventStepBack">Back</b-button>
        <b-button v-on:click="eventStepNext">Create a new event >></b-button>
      </div>
    </span>

    <span v-if="eventStep==3">
      <div>
        <div class="mb-2"><strong>Event date:</strong> {{ this.form.date }}</div>
        <div class="mb-4">
          <b-form>
            <span>
            <label for="regulator-input">Regulator</label>
            <b-form-input id="regulator-input" v-model="eventRegulator"></b-form-input>
            <label for="type-of-event-input">Type of event</label>
            <b-form-select id="type-of-event-input" v-model="eventTypeSelected" :options="eventTypeOptions"></b-form-select>
            </span>
            <b-form-group v-if="eventTypeSelected === '...'" id="customTypeGroup" label="Custom type" label-for="customTypeInput">
              <b-form-input id="customTypeInput" type="text" v-model="eventCustomType" placeholder="Custom type" required> </b-form-input>
            </b-form-group>
            <label for="subject-input">Subject of event</label>
            <b-form-input id="subject-input" v-model="eventSubject"></b-form-input>
          </b-form>
          <div class="my-4">
            <strong>Title: {{eventTitle}}</strong>
          </div>
          <b-form-group id="commentsGroup" label="Comments" label-for="commentsInput">
            <b-form-textarea id="commentsInput" v-model="form.comments" required :rows="6" placeholder="Comments around the impact"> </b-form-textarea>
          </b-form-group>
        </div>
        <b-button class="mr-2" variant="danger" v-on:click="eventStepCancel">Cancel</b-button>
        <b-button class="mr-2" v-on:click="eventStepBack">Back</b-button>
        <b-button v-if="validateStep2" v-on:click="saveEvent">Save</b-button>
      </div>
    </span>

    <!-- topics -->
    <span v-if="showTopicsFlag">
      <div class="mb-5">
        <select-list-groups
          :editable="false"
          :editAlwaysOn="true"
          :labelDelField="'del'"
          :labelLinkingField="'regtopic_id'"
          :labelModel="'regtopic'"
          :linkingModel="'publicationregtopic'"
          :name="'Regtopics'"
          :nameProperty="'name'"
          :parentId="propItem.id"
          :parentLinkingField="'publication_id'"
          :relation="'publications'"
        ></select-list-groups>
      </div>
    </span>

    <!-- risks -->
    <span v-if="showRisksFlag">
      <edge-select-list
        :considerDelProp="false"
        directionProp="from vertex"
        edgeLabelProp="newsToRisk"
        :listSourceProp="{ partition: 'events', label: 'risk' }"
        nameProp="Relevant risks"
        :vertexProp="item"
      ></edge-select-list>
    </span>

    <!-- tags -->
    <span v-if="showTagsFlag">
      <div v-if="item.tags">
        Types:
        <span v-for="tag in item.tags" :key="'tag2' + tag.id">
          {{ tag.name }}
          <b-button variant="outline-primary" class="mr-2" size="sm" v-on:click="deleteTag(tag.id, 'duplicate')">remove - duplicate</b-button>
          <b-button variant="outline-primary" class="mr-2" size="sm" v-on:click="deleteTag(tag.id, 'error')">remove - error</b-button>
          <b-button variant="outline-primary" size="sm" v-on:click="deleteTag(tag.id, 'other')">remove - other</b-button>
        </span>
      </div>
      <span v-if="!saving">
        <b-form-select class="mb-2" v-model="tags" :options="options.tags"></b-form-select>
        <b-button v-if="validateTags" class="mr-2" variant="primary" size="sm" v-on:click="saveTag">Save</b-button>
      </span>
      <span v-if="saving"><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></span>
    </span>

    <span v-if="showRiclistsFlag">
      <div class="mb-5">
        <select-list-slim
          :editable="false"
          :editAlwaysOn="true"
          :labelLinkingField="'riclist_id'"
          :labelModel="'riclist'"
          :linkingModel="'publicationriclist'"
          :name="'RIC Lists'"
          :nameProperty="'name'"
          :parentId="propItem.id"
          :parentLinkingField="'publication_id'"
          :relation="'publications'"
        ></select-list-slim>
      </div>
    </span>
<!--
    <span v-if="permissionReadTagsPanel && item.tagsauto && item.regtopicsauto">
      <div v-if="item.tagsauto.length > 0 || item.regtopicsauto.length > 0">
        <b-container class="my-4 text-center">
          <b-row class="bg-kpmg-medium-blue text-white py-2">
            <b-col><strong>Auto suggestions</strong></b-col>
          </b-row>

          <span v-if="item.tagsauto.length > 0">
          <b-row v-for="tag in item.tagsauto" class="my-4" align-v="center" :key="'tagsauto' + tag.id">
            <b-col><b-button variant="danger" v-on:click="tagReject(tag)">REJECT</b-button></b-col>
            <b-col>
              <div><strong>TAG</strong></div>
              <div>{{tag.name}}</div>
            </b-col>
            <b-col><b-button variant="primary" v-on:click="tagAccept(tag)">ACCEPT</b-button></b-col>
          </b-row>
          </span>

          <span v-if="item.regtopicsauto.length > 0">
          <b-row v-for="topic in item.regtopicsauto" class="my-4" align-v="center" :key="'topicsauto' + topic.id">
            <b-col><b-button variant="danger" v-on:click="topicReject(topic)">REJECT</b-button></b-col>
            <b-col>
              <div><strong>TOPIC</strong></div>
              <div>{{topic.name}}</div>
            </b-col>
            <b-col><b-button variant="primary" v-on:click="topicAccept(topic)">ACCEPT</b-button></b-col>
          </b-row>
          </span>

          <b-row v-if="tagsautoBusy">
            <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
          </b-row>
        </b-container>

      </div>
    </span>
-->
    <div v-if="item.langCode !== 'en'">
      <b-button @click="visibleLocal = !visibleLocal" variant="outline-primary">source in {{ item.langName }}</b-button>
      <b-collapse v-model="visibleLocal" class="mt-2">
      <h4 class="card-title"> {{ item.nameLocal }}</h4>
      <div class="mb-2">{{ item.bodyLocal }}</div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import EdgeSelectList from '@/components/cosmos/EdgeSelectList.vue'
import Event from '@/components/Event.vue'
import SelectListGroups from '@/components/sql/SelectListGroups.vue'
import SelectListSlim from '@/components/sql/SelectListSlim.vue'

export default {
  name: 'Publication',
  props: {
    'hideLink': {
      type: Boolean,
      required: false,
      default: false
    },
    'propItem': {
      type: Object,
      required: true
    },
    'permissionAddArticle': {
      type: Boolean,
      required: false,
      default: false
    },
    'permissionAddRisk': {
      type: Boolean,
      required: false,
      default: false
    },
    'permissionAddTag': {
      type: Boolean,
      required: false,
      default: false
    },
    'permissionArticlesWip': {
      type: Boolean,
      required: false,
      default: false
    },
    'permissionDeletePublication': {
      type: Boolean,
      required: false,
      default: false
    },
    'permissionInsights': {
      type: Boolean,
      required: false,
      default: false
    },
    'permissionReadTagsPanel': {
      type: Boolean,
      required: false,
      default: false
    },
    'tagsProp': {
      type: Array,
      required: true
    },
    'username': {
      type: String,
      required: true
    }
  },
  computed: {
    body: function () {
      if (this.item.body) {
        if (this.item.body.length > 400) {
          return this.item.body.slice(0, 399) + '...'
        } else {
          return this.item.body
        }
      } else {
        return ''
      }
    },
    eventTitle: function () {
      if (this.eventTypeSelected !== '...') {
        return this.eventRegulator + ' - ' + this.eventTypeSelected + ' - ' + this.eventSubject
      } else {
        return this.eventRegulator + ' - ' + this.eventCustomType + ' - ' + this.eventSubject
      }
    },
    showAutoTagsBadge: {
      get () {
        return this.$store.state.showAutoTagsBadge
      }
    },
    validStep1: function () {
      if (this.form.date) {
        return true
      } else {
        return false
      }
    },
    validateStep2: function () {
      return this.eventTitle && this.form.comments
    },
    validateTags: function () {
      return this.tags
    }
  },
  created: async function () {
    this.item.articlesPublished = []
    this.item.articlesWip = []
    _.each(this.item.articles, x => {
      if (x.approved_by) {
        this.item.articlesPublished.push(x)
      } else {
        this.item.articlesWip.push(x)
      }
    })
    this.item.partition = 'regsource'
    if (this.hideLink) {
      this.showLink = false
    }
    this.buildOptionsTags()
    if (!this.item.events) {
      this.item.events = 0
    }
    this.item.tags = _.sortBy(this.item.tags, ['name'])
  },
  components: {
    EdgeSelectList,
    Event,
    SelectListGroups,
    SelectListSlim
  },
  data () {
    return {
      buttonvariant: 'primary',
      events: [],
      eventsDay: [],
      eventCustomType: '',
      eventPermissions: {
        add: false,
        delete: false,
        edit: false
      },
      eventRegulator: this.propItem.publisher,
      eventStep: 0,
      eventSubject: '',
      eventTypeSelected: ' ',
      eventTypeOptions: [
        { value: ' ', text: 'Select event type' },
        { value: 'consultation deadline', text: 'consultation deadline' },
        { value: 'final rules published', text: 'final rules published' },
        { value: 'rules apply', text: 'rules apply' },
        { value: 'expected announcement', text: 'expected announcement' },
        { value: 'expected publication', text: 'expected publication' },
        { value: 'expected release', text: 'expected release' },
        { value: '...', text: 'other - manual' }
      ],
      form: {
        comments: '',
        date: '',
        title: ''
      },
      formEventValidated: false,
      item: this.propItem,
      loadingEvents: false,
      options: {
        tags: []
      },
      saving: false,
      showEventsFlag: false,
      showLink: true,
      showRiclistsFlag: false,
      showRisksFlag: false,
      showTagsFlag: false,
      showTopicsFlag: false,
      tags: null,
      tagsautoBusy: false,
      visibleLocal: false
    }
  },
  methods: {
    addArticle: async function () {
      try {
        const config = {
          article: {
            title: _.truncate(this.item.name, { length: 252 }),
            summary: 'Provide a short one paragraph summary',
            body: '<p>work in progress...</p>',
            created_by: this.username
          },
          events: this.item.events.map(x => x.id),
          publications: [this.item.id],
          regtopics: this.item.regtopics.map(x => x.id),
          risks: this.item.risks.map(x => x.id),
          tags: this.item.tags.map(x => x.id)
        }
        console.log(config)
        // let response = await this.$http(config)
        let params = {
          body: config
        }
        let response = await this.$Amplify.API.put('cosmos', '/article', params)
        this.$logger.debug('saved OK:', response)
        const url = window.location.protocol + '//' + window.location.host + '/workflow/article/' + response.id
        window.open(url)
      } catch (e) {
        this.$logger.warn('event saving error: ' + e)
      }
    },
    buildOptionsTags: async function () {
      const tags = this.tagsProp
      let optionsTemp = []
      optionsTemp[0] = { value: null, text: 'Select type' }
      for (let i = 0, len = tags.length; i < len; i++) {
        optionsTemp[i + 1] = {
          value: tags[i].id,
          text: tags[i].name
        }
      }
      if (this.item.tags) {
        for (let i = 0, len = this.item.tags.length; i < len; i++) {
          _.remove(optionsTemp, _.matchesProperty('value', this.item.tags[i].id))
        }
      } else {
        this.item.tags = []
      }
      this.options.tags = optionsTemp
    },
    clearEventForm: function () {
      _.forIn(this.form, (value, key) => {
        this.form[key] = ''
      })
    },
    createEvent: function (event) {
      this.eventStep = 1
      // this.$refs.eventModal.show()
    },
    deleteAi: async function () {
      try {
        await this.$Amplify.API.del('cosmos', `/news/ai/summary-and-keywords/${this.item.id}`)
        this.item.summary = null
        this.item.keywords = []
      } catch (e) {
        this.$logger.warn('delete error: ' + e)
      }
    },
    deleteTag: async function (tagIdDelete, reason) {
      this.saving = true
      try {
        await this.$Amplify.API.del('cosmos', `/publicationtag/publication/${this.item.id}/tag/${tagIdDelete}/reason/${reason}/username/${this.username}`)
        let temp = this.item.tags
        _.pullAllBy(temp, [{ 'id': tagIdDelete }], 'id')
        this.item.tags = temp
        this.buildOptionsTags()
      } catch (e) {
        this.$logger.warn('delete error: ' + e)
      }
      this.saving = false
    },
    deletePublication: async function () {
      try {
        let apiName = 'cosmos'
        let path = `/publication/${this.item.id}`
        await this.$Amplify.API.del(apiName, path)
        this.item.name = 'DELETED'
        this.item.type = 'DELETED'
      } catch (e) {
        this.$logger.warn('event saving error: ' + e)
      }
    },
    eventStepBack: function () {
      this.eventStep--
    },
    eventStepCancel: function () {
      this.clearEventForm()
      this.eventStep = 0
    },
    eventStepNext: function () {
      this.eventStep++
      if (this.eventStep === 2) {
        this.getEventsDay()
      }
    },
    getEventsDay: async function () {
      try {
        // let response = await this.$http(config)
        let date = moment(this.form.date).format('YYYY-MM-DD')
        let apiName = 'cosmos'
        let path = `/events/date/${date}`
        let response = await this.$Amplify.API.get(apiName, path)
        this.eventsDay = response
        if (this.eventsDay.length === 0) {
          this.eventStep++
        }
      } catch (e) {
        this.$logger.warn('error getting events for a day', e)
      }
    },
    isTopicAutoTagged: function (id) {
      if (this.item.publications_regtopics) {
        if (this.item.publications_regtopics.length > 0) {
          return this.item.publications_regtopics.find(x => {
            return x.regtopic_id === id && x.username === 'auto.tagger@kpmg.co.uk'
          })
        } else {
          return false
        }
      } else {
        return false
      }
    },
    isTypeAutoTagged: function (id) {
      if (this.item.publications_tags) {
        if (this.item.publications_tags.length > 0) {
          return this.item.publications_tags.find(x => {
            return x.tag_id === id && x.username === 'auto.tagger@kpmg.co.uk'
          })
        } else {
          return false
        }
      } else {
        return false
      }
    },
    moment: function (value) {
      return moment(value)
    },
    saveEvent: async function () {
      this.$logger.debug('saving event start')
      try {
        // let response = await this.$http(config)
        let apiName = 'cosmos'
        let path = `/event`
        this.form.title = this.eventTitle
        let params = {
          body: {
            event: this.form,
            publication: this.item,
            username: this.username
          }
        }
        let response = await this.$Amplify.API.put(apiName, path, params)
        this.$logger.debug('saved OK:', response)
        this.item.events++
        this.clearEventForm()
        this.showEvents()
      } catch (e) {
        this.$logger.warn('event saving error: ' + e)
      }
    },
    hideEvents: function () {
      this.showEventsFlag = false
    },
    linkEvent: async function (event) {
      const key = event.currentTarget.getAttribute('uid')
      try {
        let apiName = 'cosmos'
        let path = `/event/${key}/linkToPublication/${this.item.id}/user/${this.username}`
        let response = await this.$Amplify.API.put(apiName, path)
        this.$logger.debug('saved event', response)
        this.item.events++
        this.showEvents()
      } catch (e) {
        this.$logger.warn('error linking event', e)
      }
    },
    regTopics: function () {
      this.resetShowFlags()
      this.showTopicsFlag = true
    },
    resetShowFlags: function () {
      this.showEventsFlag = false
      this.showTopicsFlag = false
      this.showRiclistsFlag = false
      this.showRisksFlag = false
      this.showTagsFlag = false
      this.clearEventForm()
      this.eventStep = 0
    },
    riclists: function () {
      this.resetShowFlags()
      this.showRiclistsFlag = true
    },
    risks: function () {
      this.resetShowFlags()
      this.showRisksFlag = true
    },
    saveTag: async function () {
      this.$logger.debug(`saving tag start`)
      this.saving = true
      try {
        // let response = await this.$http(config)
        let apiName = 'cosmos'
        let path = `/standard/publicationtag`
        let params = {
          body: {
            publication_id: this.item.id,
            tag_id: this.tags,
            username: this.username
          }
        }
        let response = await this.$Amplify.API.put(apiName, path, params)
        this.$logger.debug('saved OK:', response)
        let tag = _.find(this.tagsProp, { 'id': this.tags })
        this.item.tags.push(tag)
        this.item.tags = _.sortBy(this.item.tags, ['name'])
        let options = this.options.tags
        _.remove(options, _.matchesProperty('value', this.tags))
        this.options.tags = [{ value: null, text: null }]
        this.options.tags = options
        this.tags = null
      } catch (e) {
        this.$logger.warn('event saving error: ' + e)
      }
      this.saving = false
    },
    showEvents: async function () {
      this.resetShowFlags()
      this.showEventsFlag = true
      this.loadingEvents = true
      try {
        let apiName = 'cosmos'
        let path = `/events/publication/${this.item.id}`
        let response = await this.$Amplify.API.get(apiName, path)
        this.loadingEvents = false
        this.events = response
        if (this.events.length === 0) {
          this.eventStep = 1
        }
      } catch (e) {
        console.log('saved ERROR: ' + e)
      }
    },
    tagAccept: async function (tag) {
      _.pullAllBy(this.item.tagsauto, [tag], 'id')
      _.remove(this.options.tags, _.matchesProperty('value', tag.id))
      this.item.tags.push(tag)
      this.tagsautoBusy = true
      try {
        let apiName = 'cosmos'
        let path = `/tag/auto/accept`
        let params = {
          body: {
            publication_id: this.item.id,
            tag_id: tag.id,
            username: this.username
          }
        }
        await this.$Amplify.API.put(apiName, path, params)
      } catch (e) {
        this.$logger.warn('event saving error: ' + e)
      }
      this.tagsautoBusy = false
    },
    tagReject: async function (tag) {
      _.pullAllBy(this.item.tagsauto, [tag], 'id')
      this.tagsautoBusy = true
      try {
        let apiName = 'cosmos'
        let path = `/tag/auto/reject`
        let params = {
          body: {
            publication_id: this.item.id,
            tag_id: tag.id,
            username: this.username
          }
        }
        await this.$Amplify.API.put(apiName, path, params)
      } catch (e) {
        this.$logger.warn('event saving error: ' + e)
      }
      this.tagsautoBusy = false
    },
    tagsEdit: function () {
      this.resetShowFlags()
      this.showTagsFlag = true
    },
    topicAccept: async function (topic) {
      _.pullAllBy(this.item.regtopicsauto, [topic], 'id')
      this.item.regtopics.push(topic)
      this.tagsautoBusy = true
      try {
        let apiName = 'cosmos'
        let path = `/regtopic/auto/accept`
        let params = {
          body: {
            publication_id: this.item.id,
            regtopic_id: topic.id,
            username: this.username
          }
        }
        await this.$Amplify.API.put(apiName, path, params)
      } catch (e) {
        this.$logger.warn('event saving error: ' + e)
      }
      this.tagsautoBusy = false
    },
    topicReject: async function (topic) {
      _.pullAllBy(this.item.regtopicsauto, [topic], 'id')
      this.tagsautoBusy = true
      try {
        let apiName = 'cosmos'
        let path = `/regtopic/auto/reject`
        let params = {
          body: {
            publication_id: this.item.id,
            regtopic_id: topic.id,
            username: this.username
          }
        }
        await this.$Amplify.API.put(apiName, path, params)
      } catch (e) {
        this.$logger.warn('event saving error: ' + e)
      }
      this.tagsautoBusy = false
    },
    updateEventCount: function () {
      this.item.events = this.item.events - 1
      if (this.eventsCount === 0) {
        this.hideEvents()
      }
    }
  }
}
</script>

<style></style>
