<template>
  <div id="app" class="bg-white">
    <div v-if="loading" style="height: 100vh; background-color: #00348d; color: white;">
      <b-container class="text-center">
        <h1><i class="fa fa-spinner fa-pulse fa-lg fa-fw" style="font-size: 4em; margin-top: 200px;"></i></h1>
      </b-container>
    </div>
    <div v-if="!loading">
      <b-navbar type="light" fixed="top" class="bg-white" style="height: 80px;">
        <b-button class="mr-4" variant="light" v-b-toggle.nav-sidebar><b-icon-grid3x3-gap-fill></b-icon-grid3x3-gap-fill></b-button>
        <router-link :to="{ name: 'Home' }" class="navbar-brand mr-4">
          <img :src="baseUrl + '/lg.jpg'" width="107.5" height="43" />
        </router-link>
        <b-navbar-brand>{{ brand }}</b-navbar-brand>
        <!-- <div class="collapse navbar-collapse justify-content-between bg-white" id="navbarNavDropdown"> -->
          <b-navbar-nav v-if="($config.BUILD === 'external' || $config.BUILD === 'externalkpmgarc') && username" class="ml-auto">
            <b-nav-item right @click="signOutExternal">
              {{ username }} <i class="fa fa-sign-out" aria-hidden="true"></i>
            </b-nav-item>
          </b-navbar-nav>
          <b-navbar-nav v-if="$config.BUILD === 'full'" class="ml-auto">
            <b-nav-item right>
              <span v-if="$config.SHOW_LOGOUT">
                <small><router-link :to="{ name: 'SignOutCognitoAuth' }" class="nav-link text-primary">{{ user.username }} <i class="fa fa-sign-out" aria-hidden="true"></i></router-link></small>
              </span>
              <span v-else>
                <small>{{ user.username }}</small>
              </span>
            </b-nav-item>
          </b-navbar-nav>
      </b-navbar>

      <b-sidebar id="nav-sidebar" backdrop-variant="dark" backdrop shadow>
          <b-nav vertical>
            <b-nav-item-dropdown
              v-if="$config.BUILD === 'full' || key"
              id="regulatory-horizon-dropdown"
              text="Horizon"
              toggle-class="text-primary"
            >
              <b-dropdown-item><router-link :to="{ name: 'Start' }" class="dropdown-item">Home</router-link></b-dropdown-item>
              <b-dropdown-item><router-link :to="{ name: 'Events' }" class="dropdown-item">Events</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.readInsights"><router-link :to="{ name: 'Articles' }" class="dropdown-item">Insights</router-link></b-dropdown-item>
              <b-dropdown-item><router-link :to="{ name: 'NewsAdvancedFilter' }" class="dropdown-item">News</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.articles"><router-link :to="{ name: 'Riclists' }" class="dropdown-item">RIC Lists</router-link></b-dropdown-item>
              <b-dropdown-item><router-link :to="{ name: 'Signals' }" class="dropdown-item">Signals</router-link></b-dropdown-item>
              <b-dropdown-item><router-link :to="{ name: 'Snapshot' }" class="dropdown-item">Snapshot</router-link></b-dropdown-item>
              <b-dropdown-item><router-link :to="{ name: 'Regulators' }" class="dropdown-item">Sources</router-link></b-dropdown-item>
              <b-dropdown-item v-if="$config.BUILD === 'full'"><router-link :to="{ name: 'Tags' }" class="dropdown-item">Tags</router-link></b-dropdown-item>
              <b-dropdown-item><router-link :to="{ name: 'Topics' }" class="dropdown-item">Topics</router-link></b-dropdown-item>
              <b-dropdown-item><router-link :to="{ name: 'Types' }" class="dropdown-item">Types</router-link></b-dropdown-item>
            </b-nav-item-dropdown>
            <b-nav-item-dropdown
              id="obligations-dropdown"
              text="Obligations"
              toggle-class="text-primary"
              v-if="$config.BUILD === 'full'"
            >
              <b-dropdown-item v-if="permissions.regulationRead"><router-link :to="{ name: 'RegDecompEntities' }" class="dropdown-item text-primary">Entities</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.regulationRead"><router-link :to="{ name: 'RegDecompFunctions' }" class="dropdown-item text-primary">Functions</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.regulationRead"><router-link :to="{ name: 'RegDecompJournal' }" class="dropdown-item text-primary">Journal</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.regulationRead"><router-link :to="{ name: 'RegDecompObligations' }" class="dropdown-item text-primary">Obligations</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.processRead"><router-link :to="{ name: 'Processes' }" class="dropdown-item text-primary">Processes</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.regulationRead"><router-link :to="{ name: 'RegDecompRegulations' }" class="dropdown-item text-primary">Regulations</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.regulatorRead"><router-link :to="{ name: 'Regulators2' }" class="dropdown-item text-primary">Regulators</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.relationships"><router-link :to="{ name: 'Relationships' }" class="dropdown-item text-primary">Relationships</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.regulationRead"><router-link :to="{ name: 'RegDecompRequirements' }" class="dropdown-item text-primary">Requirements</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.regulationRead"><router-link :to="{ name: 'Riskcomponents' }" class="dropdown-item text-primary">Risk framework</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.regulationAdd"><router-link :to="{ name: 'RegDecompStatic' }" class="dropdown-item text-primary">Static</router-link></b-dropdown-item>
              <b-dropdown-item><router-link :to="{ name: 'RegDecompAbout' }" class="dropdown-item text-primary">About</router-link></b-dropdown-item>
            </b-nav-item-dropdown>
            <li class="nav-item" v-if="$config.BUILD === 'full'">
                <router-link :to="{ name: 'Ai' }" class="nav-link text-primary">AI Regulatory Radar</router-link>
            </li>
            <li class="nav-item" v-if="permissions.cpd">
                <router-link :to="{ name: 'CPRD' }" class="nav-link text-primary">Climate Policy and Regulatory Dashboard</router-link>
            </li>
            <li class="nav-item" v-if="$config.BUILD === 'full'">
                <router-link :to="{ name: 'Commodities' }" class="nav-link text-primary">Commodities</router-link>
            </li>
            <li class="nav-item" v-if="$config.BUILD === 'full'">
                <router-link :to="{ name: 'Controls' }" class="nav-link text-primary">Controls</router-link>
            </li>
            <li class="nav-item" v-if="$config.BUILD === 'full'">
                <router-link :to="{ name: 'Tax' }" class="nav-link text-primary">ESG Tax Dashboard</router-link>
            </li>
            <li class="nav-item" v-if="$config.BUILD === 'full'">
                <router-link :to="{ name: 'Mar' }" class="nav-link text-primary">Market Abuse Insights</router-link>
            </li>
            <li class="nav-item" v-if="$config.BUILD === 'external' && permissions.mar">
                <router-link :to="{ name: 'Mar' }" class="nav-link text-primary">Market Abuse Insights</router-link>
            </li>
            <b-nav-item-dropdown
              id="esg-dropdown"
              text="ESG"
              toggle-class="text-primary"
              v-if="$config.BUILD === 'full'"
            >
              <b-dropdown-item><router-link :to="{ name: 'MetricsHome' }" class="dropdown-item text-primary">ESG Metrics</router-link></b-dropdown-item>
              <b-dropdown-item><router-link :to="{ name: 'ImpactInitiatives', query: { p: 'esgReporting' }}" class="dropdown-item text-primary">ESG Reporting</router-link></b-dropdown-item>
              <b-dropdown-item><router-link :to="{ name: 'ImpactInitiatives', query: { p: 'esgTax' }}" class="dropdown-item text-primary">ESG Tax</router-link></b-dropdown-item>
              <b-dropdown-item><router-link :to="{ name: 'CPRD' }" class="dropdown-item text-primary">CPRD</router-link></b-dropdown-item>
              <b-dropdown-item><router-link :to="{ name: 'Article', params: { id: 930 }}" class="dropdown-item text-primary">Glossary</router-link></b-dropdown-item>
              <b-dropdown-item><router-link v-if="permissions.impactreportingmi" :to="{ name: 'Reportingmi' }" class="dropdown-item text-primary">Reporting MI</router-link></b-dropdown-item>
              <b-dropdown-item><router-link v-if="permissions.impactreportingmi" :to="{ name: 'ImpactStats' }" class="dropdown-item text-primary">Visitor analytics</router-link></b-dropdown-item>
            </b-nav-item-dropdown>
            <b-nav-item-dropdown
              id="impact-dropdown"
              text="IMPACT"
              toggle-class="text-primary"
              v-if="$config.BUILD === 'full'"
            >
              <b-dropdown-item><router-link :to="{ name: 'ImpactInitiatives', query: { p: 'esgReporting' }}" class="dropdown-item text-primary">ESG Reporting</router-link></b-dropdown-item>
              <b-dropdown-item><router-link :to="{ name: 'ImpactInitiatives', query: { p: 'esgTax' }}" class="dropdown-item text-primary">ESG Tax</router-link></b-dropdown-item>
              <b-dropdown-item><router-link :to="{ name: 'ImpactInitiatives' }" class="dropdown-item text-primary">All Initiatives</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.assurance"><router-link :to="{ name: 'Assurance' }" class="dropdown-item text-primary">Assurance</router-link></b-dropdown-item>
              <b-dropdown-item><router-link :to="{ name: 'Experts' }" class="dropdown-item text-primary">Experts</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.regulatorRead"><router-link :to="{ name: 'Regulators2' }" class="dropdown-item text-primary">Regulators</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.reportingframework"><router-link :to="{ name: 'Reportingframework' }" class="dropdown-item text-primary">Reporting framework</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.reportingfrequency"><router-link :to="{ name: 'Reportingfrequency' }" class="dropdown-item text-primary">Reporting frequency</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.impactstatus"><router-link :to="{ name: 'Impactstatus' }" class="dropdown-item text-primary">Status</router-link></b-dropdown-item>
              <b-dropdown-item><router-link :to="{ name: 'ImpactTopics' }" class="dropdown-item text-primary">Topics</router-link></b-dropdown-item>
              <b-dropdown-item><router-link :to="{ name: 'ImpactAbout' }" class="dropdown-item text-primary">About</router-link></b-dropdown-item>
            </b-nav-item-dropdown>
            <!--
            <b-nav-item-dropdown
              id="control-dropdown"
              text="Controls old"
              toggle-class="text-primary"
              v-if="$config.BUILD === 'full'"
            >
              <b-dropdown-item v-if="permissions.controls"><router-link :to="{ name: 'ControlsOld' }" class="dropdown-item text-primary">Controls</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.projects"><router-link :to="{ name: 'ProjectsOld' }" class="dropdown-item text-primary">Projects</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.controls"><router-link :to="{ name: 'ControlRisksOld' }" class="dropdown-item text-primary">Risks</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.controls"><router-link :to="{ name: 'ControlStatusesOld' }" class="dropdown-item text-primary">Status</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.controls"><router-link :to="{ name: 'ControlThemesOld' }" class="dropdown-item text-primary">Themes</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.controls"><router-link :to="{ name: 'RiskcomponentsOld' }" class="dropdown-item text-primary">Risk framework</router-link></b-dropdown-item>
              <b-dropdown-item><router-link :to="{ name: 'ControlAbout' }" class="dropdown-item text-primary">About</router-link></b-dropdown-item>
            </b-nav-item-dropdown>
            -->
            <b-nav-item-dropdown
              id="faq-dropdown"
              text="FAQs"
              toggle-class="text-primary"
              v-if="$config.BUILD === 'full'"
            >
              <b-dropdown-item v-if="permissions.faqs"><router-link :to="{ name: 'Faqs' }" class="dropdown-item text-primary">FAQs</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.projects"><router-link :to="{ name: 'Projects' }" class="dropdown-item text-primary">Projects</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.ugroup"><router-link :to="{ name: 'UGroups' }" class="dropdown-item text-primary">User groups</router-link></b-dropdown-item>
              <b-dropdown-item><router-link :to="{ name: 'FaqAbout' }" class="dropdown-item text-primary">About</router-link></b-dropdown-item>
            </b-nav-item-dropdown>
            <b-nav-item-dropdown
              id="control-dropdown"
              text="MAR"
              toggle-class="text-primary"
              v-if="$config.BUILD === 'full' && permissions.mar"
            >
              <b-dropdown-item v-if="$config.BUILD === 'full'"><router-link :to="{ name: 'Alerts' }" class="dropdown-item text-primary">Alerts</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.assetclass"><router-link :to="{ name: 'Assetclasses' }" class="dropdown-item text-primary">Asset classes</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.case"><router-link :to="{ name: 'Cases' }" class="dropdown-item text-primary">Cases</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.coverage"><router-link :to="{ name: 'Coverage' }" class="dropdown-item text-primary">Coverage</router-link></b-dropdown-item>
              <b-dropdown-item><router-link :to="{ name: 'MarDashboard' }" class="dropdown-item text-primary">Dashboard</router-link></b-dropdown-item>
              <b-dropdown-item><router-link :to="{ name: 'Risks' }" class="dropdown-item text-primary">MAR risks</router-link></b-dropdown-item>
              <b-dropdown-item><router-link :to="{ name: 'Topic', params: { id: 1 }}" class="dropdown-item text-primary">News</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.person"><router-link :to="{ name: 'Persons' }" class="dropdown-item text-primary">Persons</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.persontype"><router-link :to="{ name: 'Persontypes' }" class="dropdown-item text-primary">Person types</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.producttype"><router-link :to="{ name: 'Producttypes' }" class="dropdown-item text-primary">Product types</router-link></b-dropdown-item>
            </b-nav-item-dropdown>
            <!--
            <b-nav-item-dropdown
              id="control-dropdown"
              text="MAR"
              toggle-class="text-primary"
              v-if="$config.BUILD === 'external' && permissions.mar"
            >
              <b-dropdown-item><router-link :to="{ name: 'Cases' }" class="dropdown-item text-primary">Cases</router-link></b-dropdown-item>
              <b-dropdown-item><router-link :to="{ name: 'MarDashboard' }" class="dropdown-item text-primary">Dashboard</router-link></b-dropdown-item>
              <b-dropdown-item><router-link :to="{ name: 'Risks' }" class="dropdown-item text-primary">MAR risks</router-link></b-dropdown-item>
              <b-dropdown-item><router-link :to="{ name: 'Topic', params: { id: 1 }}" class="dropdown-item text-primary">News</router-link></b-dropdown-item>
            </b-nav-item-dropdown>
            -->
            <b-nav-item-dropdown id="control-dropdown" text="More" toggle-class="text-primary" v-if="$config.BUILD === 'full'">
              <b-dropdown-item><router-link :to="{ name: 'Docs' }" class="dropdown-item text-primary">Documentation</router-link></b-dropdown-item>
              <b-dropdown-item><router-link :to="{ name: 'Notifications' }" class="dropdown-item text-primary">Notifications</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.sanctions"><router-link :to="{ name: 'Sanctions' }" class="dropdown-item text-primary">Sanctions</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.trainingcases"><router-link :to="{ name: 'Trainingcases' }" class="dropdown-item text-primary">Training cases</router-link></b-dropdown-item>
            </b-nav-item-dropdown>
            <b-nav-item-dropdown
              id="admin-dropdown"
              text="Admin"
              toggle-class="text-primary"
              v-if="permissions.adminMenu && $config.BUILD === 'full'"
            >
              <b-dropdown-item v-if="permissions.accounts"><router-link :to="{ name: 'Accounts' }" class="dropdown-item text-primary">Accounts</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.articles"><router-link :to="{ name: 'WorkflowArticles' }" class="dropdown-item text-primary">Articles</router-link></b-dropdown-item>
              <b-dropdown-item><router-link :to="{ name: 'AutotagsStats' }" class="dropdown-item text-primary">Autotags stats</router-link></b-dropdown-item>
              <b-dropdown-item><router-link :to="{ name: 'LinksAdmin' }" class="dropdown-item text-primary">Links scraping</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.addPublication"><router-link :to="{ name: 'PublicationAdd' }" class="dropdown-item text-primary">News - add</router-link></b-dropdown-item>
              <b-dropdown-item><router-link :to="{ name: 'NewsAnyTag' }" class="dropdown-item text-primary">News - any tag</router-link></b-dropdown-item>
              <b-dropdown-item><router-link :to="{ name: 'NewsCount' }" class="dropdown-item text-primary">News - count</router-link></b-dropdown-item>
              <b-dropdown-item><router-link :to="{ name: 'NewsMatrix' }" class="dropdown-item text-primary">News - matrix</router-link></b-dropdown-item>
              <b-dropdown-item><router-link :to="{ name: 'NewsSource' }" class="dropdown-item text-primary">News - source</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.notifications"><router-link :to="{ name: 'AdminNotifications' }" class="dropdown-item text-primary">Notifications</router-link></b-dropdown-item>
              <b-dropdown-item><router-link :to="{ name: 'PreferencesAdmin' }" class="dropdown-item text-primary">Preferences</router-link></b-dropdown-item>
              <b-dropdown-item><router-link :to="{ name: 'AdminRecentActivity' }" class="dropdown-item text-primary">Recent activity</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.sourceAdd"><router-link :to="{ name: 'SourceAdd' }" class="dropdown-item text-primary">Source - add</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.source"><router-link :to="{ name: 'Sources' }" class="dropdown-item text-primary">Sources - list</router-link></b-dropdown-item>
              <b-dropdown-item><router-link :to="{ name: 'SourcesTableRows' }" class="dropdown-item text-primary">Sources - table</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.stats"><router-link :to="{ name: 'Stats' }" class="dropdown-item text-primary">Statistics</router-link></b-dropdown-item>
              <b-dropdown-item><router-link :to="{ name: 'Taxonomy' }" class="dropdown-item text-primary">Taxonomy</router-link></b-dropdown-item>
              <b-dropdown-item><router-link :to="{ name: 'TopicsDeleted' }" class="dropdown-item text-primary">Topics - deleted</router-link></b-dropdown-item>
              <b-dropdown-item><router-link :to="{ name: 'AdminTags' }" class="dropdown-item text-primary">Types</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.user"><router-link :to="{ name: 'Users' }" class="dropdown-item text-primary">Users</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.userGroup"><router-link :to="{ name: 'UserGroups' }" class="dropdown-item text-primary">User groups</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.migration"><router-link :to="{ name: 'Migrations' }" class="dropdown-item text-primary">Migrations</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.migration"><router-link :to="{ name: 'Suggestrules' }" class="dropdown-item text-primary">Suggestrules</router-link></b-dropdown-item>
            </b-nav-item-dropdown>
            <b-nav-item-dropdown
              id="apps-dropdown"
              text="Apps"
              toggle-class="text-primary"
              v-if="$config.BUILD === 'full'"
            >
              <b-dropdown-item v-if="permissions.algotrading"><router-link :to="{ name: 'AppUT', params: { appid: 'algo-trading' }}" class="dropdown-item text-primary">Algorithmic Trading</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.appanalytics"><router-link :to="{ name: 'AppAnalytics' }" class="dropdown-item text-primary">Edge Analytics</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.appobligations"><router-link :to="{ name: 'AppObligations' }" class="dropdown-item text-primary">Obligations</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.nfr"><router-link :to="{ name: 'Ptt' }" class="dropdown-item text-primary">PTT</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.appimporting"><router-link :to="{ name: 'AppImport' }" class="dropdown-item text-primary">Import</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.appimporting"><router-link :to="{ name: 'AppImporting' }" class="dropdown-item text-primary">Importing News</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.appreporting"><router-link :to="{ name: 'AppReporting' }" class="dropdown-item text-primary">Reporting ACR</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.appreporting"><router-link :to="{ name: 'AppReportingZebra' }" class="dropdown-item text-primary">Reporting Zebra</router-link></b-dropdown-item>
              <b-dropdown-item v-if="permissions.ut"><router-link :to="{ name: 'AppUT', params: { appid: 'ut' }}" class="dropdown-item text-primary">Unauthorised Trading</router-link></b-dropdown-item>
              <b-dropdown-item><router-link :to="{ name: 'AppUT', params: { appid: 'about' }}" class="dropdown-item text-primary">About</router-link></b-dropdown-item>
            </b-nav-item-dropdown>
            <li class="nav-item" v-if="$config.BUILD === 'external' && permissions.initiatives">
                <router-link :to="{ name: 'ImpactInitiatives' }" class="nav-link text-primary">Initiatives</router-link>
            </li>
            <li class="nav-item" v-if="$config.BUILD === 'external' || $config.BUILD === 'externalkpmgarc'">
                <router-link :to="{ name: 'Settings' }" class="nav-link text-primary">Settings</router-link>
            </li>
            <li class="nav-item" v-if="$config.BUILD === 'external' || $config.BUILD === 'externalkpmgarc'">
                <router-link :to="{ name: 'About' }" class="nav-link text-primary">About</router-link>
            </li>
          </b-nav>
        </b-sidebar>

      <router-view></router-view>
      <div class="mt-4 p-4" style="border-top-color: rgb(0, 145, 218); border-top-style: solid; border-top-width: 9px">
        <div class="my-4">
          <b-link :to="{ name: 'Contact' }">Contact</b-link> |
          <b-link :to="{ name: 'Legal' }">Legal</b-link> |
          <b-link v-if="$config.BUILD === 'full'" :to="{ name: 'Docs' }">Documentation</b-link>
        </div>
        <div class="my-2">© 2025 KPMG LLP, a UK limited liability partnership and a member firm of the KPMG global organisation of independent member firms affiliated with KPMG International Limited, a private English company limited by guarantee. All rights reserved.</div>
        <div>For more detail about the structure of the KPMG global organisation please visit <a href="https://home.kpmg/governance">https://home.kpmg/governance</a></div>
      </div>
    </div>

    <b-modal v-model="pingModal" size="xl" ok-only title="Connection error">
      <div>
        <p>Looks like there are some server connection issues</p>
        <p>Please try to login again and if the problem persists contact Lucas Ocelewicz</p>
        <p><router-link :to="{ name: 'SignOutCognitoAuth' }" class="nav-link text-primary">LOGIN</router-link></p>
      </div>
    </b-modal>

  </div>
</template>

<script>
import ac from './libs/accesscontrol'

import { BIconGrid3x3GapFill } from 'bootstrap-vue'

function getUser () {
  const user = {}
  user.username = window.localStorage.getItem('username')
  user.sub = window.localStorage.getItem('userSub')
  user.state = window.localStorage.getItem('userState')
  user.group = window.localStorage.getItem('userGroup')
  return user
}

export default {
  name: 'app',
  components: {
    BIconGrid3x3GapFill
  },
  created: function () {
    this.$logger.debug('App.vue created start')
    this.$logger.verbose('localStorage', window.localStorage)
    this.user = getUser()
    this.newApi = this.$config.API_NEW
    if (this.$store.state.sessionShort === 0) {
      this.$store.commit('setSessionShort', Date.now())
    }
    this.$stat.setSessionShort(this.$store.state.sessionShort)
    let sessionLong = window.localStorage.getItem('frmCloudSession')
    if (sessionLong === null) {
      sessionLong = Date.now()
      window.localStorage.setItem('frmCloudSession', sessionLong)
    } else {
      sessionLong = Number(sessionLong)
    }
    this.$stat.setSessionLong(sessionLong)
    this.checkUserStatus()
  },
  updated: function () {
    this.$logger.debug('App.vue started: updated')
    this.$logger.verbose('localStorage', window.localStorage)
    this.checkUserStatus()
  },
  data () {
    return {
      baseUrl: this.$config.BASE_URL,
      ie: false,
      loading: true,
      newApi: false,
      permissions: {},
      pingModal: false
    }
  },
  computed: {
    brand: {
      get () {
        return this.$store.state.brand
      },
      set (payload) {
        this.$store.commit('setBrand', payload)
      }
    },
    key: {
      get () {
        return this.$store.state.key
      },
      set (payload) {
        this.$store.commit('setKey', payload)
      }
    },
    message: {
      get () {
        return this.$store.state.message
      },
      set (payload) {
        this.$store.commit('setMessage', payload)
      }
    },
    notifications: {
      get () {
        return this.$store.state.notifications
      },
      set (payload) {
        this.$store.commit('setNotifications', payload)
      }
    },
    user: {
      get () {
        return this.$store.state.user
      },
      set (payload) {
        this.$store.commit('setUser', payload)
      }
    },
    username: {
      get () {
        return this.$store.state.username
      },
      set (payload) {
        this.$store.commit('setUsername', payload)
      }
    },
    userState: {
      get () {
        return this.$store.state.userState
      },
      set (payload) {
        this.$store.commit('setUserState', payload)
      }
    }
  },
  methods: {
    checkUserStatus: async function () {
      if (this.$config.BUILD === 'full') {
        let user = this.user
        this.$logger.debug('App.vue, started: checkUserStatus')
        this.$logger.verbose('state.user object', this.user)
        this.userState = window.localStorage.getItem('userState')
        let whitelist = ['Auth', 'LoginCognitoAuth', 'SignOutCognitoAuth']
        if (this.userState !== 'signedIn') {
          if (!whitelist.includes(this.$route.name)) {
            window.localStorage.setItem('frmCloudRedirect', window.location.href)
            this.$router.push({ name: 'Auth', params: { action: 'signin' } })
          }
        } else {
          // ping server to check connectivity
          try {
            await this.$Amplify.API.get('cosmos', '/ping')
            window.localStorage.setItem('frmCloudReloginTrigger', 'false')
          } catch (e) {
            if (window.localStorage.getItem('frmCloudReloginTrigger') === 'true') {
              this.pingModal = true
              console.log('ping error, stuck')
            } else {
              window.localStorage.setItem('frmCloudReloginTrigger', 'true')
              window.localStorage.setItem('frmCloudRedirect', window.location.href)
              this.$router.push({ name: 'SignOutCognitoAuth' })
              console.log('ping error, reloging')
            }
          }
          // fetch user from cosmos and check permissions
          try {
            this.$logger.debug('fetching user from cosmos')
            const apiName = 'cosmos'
            const path = `/user/byemail/${user.username}`
            const response = await this.$Amplify.API.get(apiName, path)
            this.$logger.debug('response', response)
            const account = response
            if (account.userGroups.length > 0) {
              user.acgroups = account.userGroups
            } else {
              user.acgroups = ['norole']
            }
          } catch (e) {
            this.$logger.warn('fetching user from cosmos error' + e)
            user.acgroups = ['norole']
          }
          this.user = user
          this.permissions.accounts = ac.can(this.user.acgroups).readAny('account').granted
          this.permissions.addPublication = ac.can(this.user.acgroups).createAny('publication').granted
          this.permissions.adminMenu = ac.can(this.user.acgroups).readAny('adminMenu').granted
          this.permissions.adminNotifications = ac.can(this.user.acgroups).readAny('adminNotifications').granted
          this.permissions.algotrading = ac.can(this.user.acgroups).readAny('appalgotrading').granted
          this.permissions.appanalytics = ac.can(this.user.acgroups).readAny('appanalyticsdemo').granted
          this.permissions.appimporting = ac.can(this.user.acgroups).readAny('appimporting').granted
          this.permissions.appobligations = ac.can(this.user.acgroups).readAny('appobligations').granted
          this.permissions.appreporting = ac.can(this.user.acgroups).readAny('appreporting').granted
          this.permissions.articles = ac.can(this.user.acgroups).readAny('articleWorkflowEdit').granted
          this.permissions.assetclass = ac.can(this.user.acgroups).readAny('assetclass').granted
          this.permissions.assurance = ac.can(this.user.acgroups).readAny('assurance').granted
          this.permissions.case = ac.can(this.user.acgroups).readAny('case').granted
          this.permissions.comm = ac.can(this.user.acgroups).readAny('comm').granted
          this.permissions.controls = ac.can(this.user.acgroups).readAny('control').granted
          this.permissions.coverage = ac.can(this.user.acgroups).readAny('coverage').granted
          this.permissions.cpd = true
          this.permissions.faqs = ac.can(this.user.acgroups).readAny('faq').granted
          this.permissions.impactstatus = ac.can(this.user.acgroups).readAny('impactstatus').granted
          this.permissions.impactreportingmi = ac.can(this.user.acgroups).readAny('impactreportingmi').granted
          this.permissions.mar = ac.can(this.user.acgroups).readAny('marrisk').granted
          this.permissions.migration = ac.can(this.user.acgroups).createAny('migration').granted
          this.permissions.nfr = ac.can(this.user.acgroups).readAny('nfrsection').granted
          this.permissions.notifications = ac.can(this.user.acgroups).readAny('adminNotifications').granted
          this.permissions.person = ac.can(this.user.acgroups).readAny('person').granted
          this.permissions.persontype = ac.can(this.user.acgroups).readAny('persontype').granted
          this.permissions.processRead = ac.can(this.user.acgroups).readAny('process').granted
          this.permissions.producttype = ac.can(this.user.acgroups).readAny('producttype').granted
          this.permissions.projects = ac.can(this.user.acgroups).readAny('project').granted
          this.permissions.readInsights = true
          this.permissions.regulationAdd = ac.can(this.user.acgroups).createAny('regulation').granted
          this.permissions.regulationRead = ac.can(this.user.acgroups).readAny('regulation').granted
          this.permissions.regulatorRead = ac.can(this.user.acgroups).readAny('regulator').granted
          this.permissions.relationships = ac.can(this.user.acgroups).readAny('relationship').granted
          this.permissions.reportingframework = ac.can(this.user.acgroups).readAny('reportingframework').granted
          this.permissions.reportingfrequency = ac.can(this.user.acgroups).readAny('reportingfrequency').granted
          this.permissions.sanctions = ac.can(this.user.acgroups).readAny('sanction').granted
          this.permissions.source = ac.can(this.user.acgroups).readAny('source').granted
          this.permissions.sourceAdd = ac.can(this.user.acgroups).createAny('source').granted
          this.permissions.stats = ac.can(this.user.acgroups).readAny('stats').granted
          this.permissions.trainingcases = ac.can(this.user.acgroups).readAny('trainingcase').granted
          this.permissions.ugroup = ac.can(this.user.acgroups).readAny('ugroup').granted
          this.permissions.user = ac.can(this.user.acgroups).readAny('user').granted
          this.permissions.userGroup = ac.can(this.user.acgroups).readAny('userGroup').granted
          this.permissions.ut = ac.can(this.user.acgroups).readAny('apput').granted
        }
        this.loading = false
        this.username = this.user.username
        this.notifications = await this.$Amplify.API.get('cosmos', `/notifications/byemail/${this.user.username}`)
      }
      if (this.$config.BUILD === 'external' || this.$config.BUILD === 'externalkpmgarc') {
        let key = window.localStorage.getItem('frmCloudExApiKey')
        if (key === 'null') {
          key = null
          window.localStorage.removeItem('frmCloudExApiKey')
          window.localStorage.removeItem('frmCloudExUsername')
          this.$store.commit('setKey', null)
          this.$store.commit('setUsername', null)
        }
        const username = window.localStorage.getItem('frmCloudExUsername')
        if (!key && this.$route.name !== 'Legal' && this.$route.name !== 'About' && this.$route.name !== 'DigitalGateway' && this.$route.name !== 'Tempx' && this.$route.name !== null) {
          this.$router.push({ name: 'Settings' })
        }
        if (key) {
          this.key = key
          this.username = username
          this.user.username = username
          this.$stat.setUserType(1)
          try {
            const accountDB = await this.$Amplify.API.get('cosmos', '/account')
            if (!accountDB.acgroups) {
              accountDB.acgroups = ['norole']
            }
            window.localStorage.setItem('frmCloudExAcgroups', JSON.stringify(accountDB.acgroups))
            window.localStorage.setItem('frmCloudExUsername', accountDB.organisation)
            const userDB = this.user
            userDB.acgroups = accountDB.acgroups
            userDB.homepage = accountDB.homepage
            this.user = userDB
            this.permissions.readInsights = ac.can(this.user.acgroups).readAny('article').granted
            this.permissions.cpd = ac.can(this.user.acgroups).readAny('cpd').granted
            this.permissions.initiatives = ac.can(this.user.acgroups).readAny('initiative').granted
            this.permissions.mar = ac.can(this.user.acgroups).readAny('case').granted
            this.$store.commit('setUsername', accountDB.organisation)
          } catch (e) {
            this.signOutExternal()
          }
        }
        this.userState = 'signedIn'
        this.user.acgroups = JSON.parse(window.localStorage.getItem('frmCloudExAcgroups'))
        this.loading = false
      }
      this.$stat.setUser(this.username)
    },
    signOut: function () {
      this.$router.push({ name: 'SignOutCognitoAuth' })
    },
    signOutExternal: function () {
      window.localStorage.removeItem('frmCloudExApiKey')
      window.localStorage.removeItem('frmCloudExUsername')
      this.key = null
      this.username = null
      this.user = null
      this.account = false
      this.$store.commit('setKey', null)
      this.$store.commit('setUsername', null)
      this.$router.push({ name: 'Settings' })
    },
    toast: function () {
      if (this.message) {
        this.$bvToast.toast(this.message.body, {
          title: this.message.title,
          toaster: 'b-toaster-top-center',
          solid: true,
          appendToast: false
        })
        this.message = null
      }
    }
  },
  mounted () {
    window.addEventListener('scroll', checkWindowBottom.bind(this))
    async function checkWindowBottom () {
      let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight >= 0.8 * document.documentElement.scrollHeight
      if (bottomOfWindow) {
        this.$logger.verbose('hit bottom of window')
        this.$store.commit('setWindowBottom', true)
      }
    }
  },
  watch: {
    'message': 'toast'
  }
}
</script>

<style lang="scss">
.print-only {
  visibility: hidden;
}

@media print {
  * {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
  body * {
    visibility: hidden;
  }
  .print-container * {
    visibility: visible;
  }
  .print-only {
    visibility: hidden;
  }
}

@font-face {
  font-family: KPMGLight;
  // src: url(https://frmcloud.com/resources/fonts/light.woff);
  src: url(./assets/fonts/light.woff);
}

$blue: #00348d; // #005eb8; //primary
$gray-600: #005eb8; //secondary
//$green !default;
$cyan: #00a3a1; //info
//$yellow !default;
//$red !default;
//$gray-100 !default;
$gray-800: #00338d; //dark

// stylelint-disable value-keyword-case
$font-family-sans-serif: 'Open Sans', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
$font-family-monospace: 'SFMono-Regular', Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
$font-family-base: $font-family-sans-serif;

h1.kstyle {
  font-family: 'Open Sans', 'Arial', 'Helvetica', 'sans-serif';
  font-size: 28px;
  font-weight: 500;
  font-stretch: 75%;
}

h5.kpmg {
  font-family: 'Open Sans', 'Arial', 'Helvetica', 'sans-serif';
  font-size: 32px;
  font-weight: 400;
  font-stretch: 75%;
}

.bg-kpmg-blue {
  background-color: #00348d;
}
.bg-kpmg-dark-blue {
  background-color: #0c233c;
}
.bg-kpmg-light-blue {
  background-color: #aceaff;
}
.bg-kpmg-cobalt-blue {
  background-color: #1e49e2;
}
// medium is same as cobalt
.bg-kpmg-medium-blue {
  background-color: #1e49e2;
}
.bg-kpmg-purple {
  background-color: #7213ea;
}
.bg-kpmg-pink {
  background-color: #fd349c
}
// same as pink
.bg-kpmg-light-purple {
  background-color: #fd349c
}
.bg-kpmg-pacific-blue {
  background-color: #00b8f5
}
.bg-kpmg-light-green {
  background-color: #00b8f5
}

.cursor-pointer {
  cursor: pointer
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.font-kpmg {
  font-family: 'Open Sans', 'Arial', 'Helvetica', 'sans-serif';
  font-weight: 400;
  font-stretch: 75%;
}

.font-size-44 {
  font-size: 32px;
}

.font-size-32 {
  font-size: 28px;
}

.font-size-smaller {
  font-size: 0.9em;
}

@import '~bootstrap/scss/bootstrap';
@import '~bootstrap-vue/src/index.scss';

.bg-light {
  background-color: #f9f9f9 !important;
}

html {
  font-size: 14px;
}

/* used in tables e.g. in regtopics */
.pointer {
  cursor: pointer;
}

@media (min-width: 768px) {
  .main {
    padding-right: 40px;
    padding-left: 40px;
  }
}
.main .page-header {
  margin-top: 0;
}

.shadow {
  box-shadow: 0px 12px 25px #00000029;
}

/*
 * My specific styles
 */

div.pre-wrap {
  white-space: pre-wrap;
}

regbrain-highlight.regbrain-annotation {
  background-color: yellow;
}

regbrain-highlight.regbrain-annotation-transparent {
  background-color: transparent !important;
}

regbrain-highlight.regbrain-annotation-focused {
  background-color: lightblue;
}

.handbook-content {
  h1, h2, h3 {
    margin-top: 40px;
    margin-bottom: 20px;
  }
  ol, ul {
    display: block;
    list-style-type: none;
    list-style-position: inside;
  }
  li {
    margin-bottom: 20px;
  }
  li p {
    display: inline;
  }
  .details {
    font-weight: 700;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}

.legislation-uk {
  h2, h3, h4 {
    font-size: 14px
  }
  /* preface */

  .preface::before {
    display: block; text-align: center;
  }
  article[data-name='UnitedKingdomPublicGeneralAct'] > .preface::before {
    content: url('http://www.legislation.gov.uk/images/crests/ukpga.gif')
  }
  article[data-name='UnitedKingdomLocalAct'] > .preface::before {
    content: url('http://www.legislation.gov.uk/images/crests/ukla.gif')
  }
  article[data-name='ScottishAct'] > .preface::before {
    content: url('http://www.legislation.gov.uk/images/crests/asp.gif')
  }
  article[data-name='WelshParliamentAct'] > .preface::before {
    content: url('http://www.legislation.gov.uk/images/crests/asc.gif')
  }
  article[data-name='WelshNationalAssemblyAct'] > .preface::before {
    content: url('http://www.legislation.gov.uk/images/crests/anaw.gif')
  }
  article[data-name='WelshAssemblyMeasure'] > .preface::before {
    content: url('http://www.legislation.gov.uk/images/crests/mwa.gif')
  }
  article[data-name='UnitedKingdomChurchMeasure'] > .preface::before {
    content: url('http://www.legislation.gov.uk/images/crests/ukcm.gif')
  }
  article[data-name='ScottishOldAct'] > .preface::before {
    content: url('http://www.legislation.gov.uk/images/crests/aosp.gif')
  }
  article[data-name='EnglandAct'] > .preface::before {
    content: url('http://www.legislation.gov.uk/images/crests/aep.gif')
  }
  article[data-name='IrelandAct'] > .preface::before {
    content: url('http://www.legislation.gov.uk/images/crests/aip.gif')
  }
  article[data-name='GreatBritainAct'] > .preface::before {
    content: url('http://www.legislation.gov.uk/images/crests/apgb.gif')
  }

  /* preface  */

  .preface { margin: 2em 0 }
  .preface p { margin: 1em 0 }
  .preface > p.title { text-align: center }
  .preface > p.number { text-align: center }

  .docNumber { font-size: larger; font-weight: bold }

  .DateOfEnactment { text-align: right }
  article[data-name='ScottishAct'] .DateOfEnactment { text-align: left; font-weight: bold }

  .preamble { margin: 2em 0 }
  .preamble p { margin: 1em 0 }

  .preamble { margin: 2em 0 }
  .preamble p { margin: 1em 0 }

  /* hierarchy */

  section { margin-top: 1em }

  .heading { display: block }

  .part > h2 > .num { font-variant: small-caps }
  .part > h2 > .heading { font-variant: small-caps }

  .chapter > h2 > .num { font-variant: small-caps }
  .chapter > h2 > .heading { font-variant: small-caps }

  .section { position: relative; padding-left: 3em }
  .section > h2 > .num { display: inline-block; position: absolute; left: 0; font-weight: bold }
  .section > h2 > .num[data-startQuote]:before { content: attr(data-startQuote) }
  .section > h2 > .heading { font-weight: bold }

  .subsection { position: relative; padding-left: 3em }
  .subsection > h2 > span.num { display: inline-block; position: absolute; left: 0 }
  .subsection > h2 > span.num:before { content: '(' }
  .subsection > h2 > span.num[data-startQuote]:before { content: attr(data-startQuote) '(' }
  .subsection > h2 > span.num:after { content: ')' }

  .paragraph { position: relative; padding-left: 3em }
  .paragraph > * > span.num { display: inline-block; position: absolute; left: 0 }
  .paragraph > * > span.num:before { content: '(' }
  .paragraph > * > span.num[data-startQuote]:before { content: attr(data-startQuote) '(' }
  .paragraph > * > span.num:after { content: ')' }

  .subparagraph { position: relative; padding-left: 3em }
  .subparagraph > * > span.num { display: inline-block; position: absolute; left: 0 }
  .subparagraph > * > span.num:before { content: '(' }
  .subparagraph > * > span.num[data-startQuote]:before { content: attr(data-startQuote) '(' }
  .subparagraph > * > span.num:after { content: ')' }

  .clause { position: relative; padding-left: 3em }
  .clause > h2 > span.num { display: inline-block; position: absolute; left: 0 }

  *[data-name='schedules'] > h2 > span.heading { font-variant: small-caps }

  *[data-name='schedule'] > h2 > span.num { font-variant: small-caps }
  *[data-name='schedule'] > h2 > span.heading { font-variant: small-caps }

  .annotations { display: block }
  .noteRef.commentary { display: inline }
  .noteRef.C, .noteRef.E, .noteRef.I, .noteRef.P { display: none }
  ins.first::before { font-weight: bold; font-size: x-large; content: '[' }
  ins.last::after { font-weight: bold; font-size: x-large; content: ']' }

  :not(.prospective) > .prospective:not(.toc):not(.tocItem) {
    border: 1px solid #656A7B;
    background-color: #EFF5F5
  }

  :not(.prospective) > .prospective:not(.toc):not(.tocItem)::before {
    display: block;
    margin-bottom: 1em;
    background-color: #656A7B;
    text-align: right;
    padding: 6pt;
    color: white;
    content: 'Prospective'
  }

  article.primary :not(.prospective) > .section.prospective::before { margin-left: -4em; margin-right: -1em }
  article.primary .section.prospective { padding: 0 1em 1em 4em }
  article.primary .section.prospective > h2 > .num { left: 1em }

  *[data-name='schedules'].prospective::before { margin-left: -1em; margin-right: -1em }
  *[data-name='schedules'].prospective { padding: 0 1em 1em 1em }
}
</style>
